import React, { Fragment } from "react";
import SahajComponent from "components/splashScreen/SahajComponent";

function HomePage() {
  return (
    <Fragment>
      <SahajComponent
        tint={"copper"}
        caption={
          "The moment seems to flow like a molten sapphire and there’s deep blue silence,"
        }
      />
      <SahajComponent
        tint={"sunset"}
        caption={"Neither there is earth below, nor sky above,"}
      />
      <SahajComponent
        tint={"gold"}
        caption={
          "The rustling branches, leaves are saying that only you are here,"
        }
      />
      <SahajComponent
        tint={"emerald"}
        caption={"Only me, my breath and my heartbeat,"}
      />
      <SahajComponent
        tint={"cobalt"}
        caption={"Such deepness, such loneliness and me…only me,"}
      />
      <SahajComponent
        tint={"amethyst"}
        caption={"It all makes me believe in my existence."}
      />
      <SahajComponent tint={"steel"} caption={"-Javed Akhtar"} />
    </Fragment>
  );
}

export default HomePage;
