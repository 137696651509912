import { useEffect, useRef } from "react";
import {
  verticalObserver,
  horizontalObserver
} from "SKComponents/intersection/IntersectionObserver";
const useOnScreen = (vert = true) => {
  const ref = useRef();

  const observer = vert ? verticalObserver : horizontalObserver;

  useEffect(() => {
    const current = ref.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [ref]);

  return ref;
};

export default useOnScreen;
