import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useOnScreen from "SKComponents/hooks/useOnScreen";
import { FADED_STEEL } from "SKComponents/utils/Colors";
import { FADED_COLOR_MAP } from "SKComponents/utils/ColorMaps";

const StyledImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 50vmin;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

const StyledImage = styled.img`
  height: 50vmin;
  visibility: ${props => (props.load ? "visible" : "hidden")};
`;

const StyledImageOverlay = styled.div.attrs(props => ({
  style: {
    backgroundImage: `radial-gradient(
        circle at left ${props.position ? props.position.x : 0}px top
          ${props.position ? props.position.y : 0}px,
        ${FADED_STEEL},
        ${FADED_COLOR_MAP[props.tint] || FADED_STEEL}
      )`
  }
}))`
  ${props => !props.load && "display: none;"}
  position: absolute;
  opacity: ${props => (props.hover ? 100 : 0)};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.25s ease-in-out;
`;

const StyledText = styled.div`
  position: absolute;
  top: 0;
  display: ${props => (props.load ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: ${props => (props.hover ? 100 : 0)};
  transition: opacity 0.25s ease-in-out;
`;

function SKImage({ image, caption, tint, vert = true, ...props }) {
  const [hover, setHover] = useState(false);
  const [load, setLoad] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useOnScreen(vert);

  const _onMouseEnter = () => {
    setHover(true);
  };

  const _onMouseLeave = () => {
    setHover(false);
  };

  const _onMouseMove = e => {
    setPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const _onLoad = () => {
    setLoad(true);
  };

  return (
    <StyledImageWrapper
      ref={ref}
      onMouseMove={_onMouseMove}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <StyledImage src={image} load={load} onLoad={_onLoad} />
      <StyledImageOverlay
        load={load}
        hover={hover}
        tint={tint}
        position={position}
      />
      <StyledText load={load} hover={hover}>
        {caption}
      </StyledText>
    </StyledImageWrapper>
  );
}

SKImage.propTypes = {
  image: PropTypes.string.isRequired,
  caption: PropTypes.string,
  tint: PropTypes.string
};

export default SKImage;
