const TabMap = [
  { tabName: "Home", tabRoute: "/" },
  {
    tabName: "Travel",
    tabRoute: "/travel"
  },
  {
    tabName: "Book Reviews",
    tabRoute: "/books"
  },
  { tabName: "About", tabRoute: "/about" }
];

export default TabMap;
