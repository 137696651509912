import React, { Fragment } from "react";
import SKDiv from "SKComponents/SKDiv";

function AboutPage({ navigation }) {
  console.log("here");
  console.log(navigation);
  return (
    <Fragment>
      <SKDiv style={{ marginTop: "15vmin" }} />
      <SKDiv style={{ width: "75vmin" }}>
        My Name is Sahaj Kumar. I am a software developer by training, but care
        more about telling and sharing interesting stories.
      </SKDiv>
    </Fragment>
  );
}

export default AboutPage;
