import * as Colors from "./Colors";

export const FADED_COLOR_MAP = {
  emerald: Colors.FADED_EMERALD,
  copper: Colors.FADED_COPPER,
  cobalt: Colors.FADED_COBALT,
  amethyst: Colors.FADED_AMETHYST,
  gold: Colors.FADED_GOLD,
  sunset: Colors.FADED_SUNSET,
  steel: Colors.FADED_STEEL
};
