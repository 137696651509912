import React, { Fragment } from "react";
import SKImage from "SKComponents/SKImage";
import SKFlex from "SKComponents/SKFlex";
import styled from "styled-components";

const StyledImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 50vmin;
  width: 90%;
`;

const StyledFlex = styled(SKFlex)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 0.5%;
`;

const StyledTitle = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.75);
`;

const StyledGap = styled.div`
  margin-right: 0.5%;
`;

function SKImageReel({ data: { title, imageMap } }) {
  return (
    <StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledFlex spread={"left"} wrap={"nowrap"}>
        {imageMap.map(({ image, description }, index) => (
          <Fragment>
            <SKImage
              key={index}
              image={image}
              tint={"amethyst"}
              caption={description}
              vert={false}
            />
            {index < imageMap.length - 1 && <StyledGap />}
          </Fragment>
        ))}
      </StyledFlex>
    </StyledImageWrapper>
  );
}

SKImageReel.propTypes = {};

export default SKImageReel;
