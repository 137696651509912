"use es6";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledDiv = styled.div.attrs(props => ({
  style: {
    backgroundImage:
      props.hoverTint &&
      (props.hover
        ? `radial-gradient(
        circle at left ${props.position ? props.position.x : 0}px top
          ${props.position ? props.position.y : 0}px,
        rgba(255,255,255, 0.2),
        transparent
      )`
        : "radial-gradient(circle at center, rgba(255,255,255, 0.2), transparent)")
  }
}))``;

function SKDiv(props) {
  const { hoverTint } = props;

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hover, setHover] = useState(false);

  const _onMouseEnter = () => {
    setHover(true);
  };

  const _onMouseLeave = () => {
    setHover(false);
  };

  const _onMouseMove = e => {
    setPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };
  return (
    <StyledDiv
      position={position}
      hover={hover}
      onMouseEnter={hoverTint && _onMouseEnter}
      onMouseLeave={hoverTint && _onMouseLeave}
      onMouseMove={hoverTint && _onMouseMove}
      {...props}
    >
      {props.children}
    </StyledDiv>
  );
}

SKDiv.propTypes = {
  hoverTint: PropTypes.bool
};

export default SKDiv;
