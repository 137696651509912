import React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
  padding-top: 56px
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100%;
`;
function PageBase(props) {
  return <StyledPage className="container">{props.children}</StyledPage>;
}

export default PageBase;
