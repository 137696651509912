/*import andalusia5 from "images/travel/andalusia/IMG_0308.jpg";
import andalusia6 from "images/travel/andalusia/IMG_0439.png";

import andalusiaMezquitaCatedral1 from "images/travel/andalusia/mq1.jpg";
import andalusiaMezquitaCatedral2 from "images/travel/andalusia/mq2.jpg";
import andalusiaMezquitaCatedral3 from "images/travel/andalusia/mq3.jpg";
import andalusiaMezquitaCatedral4 from "images/travel/andalusia/mq4.jpg";

import andalusiaBuddySelfie1 from "images/travel/andalusia/IMG_9858.jpg";
import andalusiaBuddySelfie2 from "images/travel/andalusia/IMG_9576.jpg";
import andalusiaBuddySelfie3 from "images/travel/andalusia/IMG_0093.jpg";
import andalusiaBuddySelfie4 from "images/travel/andalusia/IMG_0308.jpg";
*/

import externalGranada1 from "images/travel/andalusia/external-granada/IMG_9167.jpg";
import externalGranada2 from "images/travel/andalusia/external-granada/IMG_9212.jpg";
import externalGranada3 from "images/travel/andalusia/external-granada/IMG_9244.jpg";
import externalGranada4 from "images/travel/andalusia/external-granada/IMG_9348.jpg";
import externalGranada5 from "images/travel/andalusia/external-granada/IMG_9357.jpg";
import externalGranada6 from "images/travel/andalusia/external-granada/IMG_9368.jpg";

import andalusiaGypsyCaves1 from "images/travel/andalusia/gypsy-caves/IMG_9378.jpg";
import andalusiaGypsyCaves2 from "images/travel/andalusia/gypsy-caves/IMG_9383.jpg";
import andalusiaGypsyCaves3 from "images/travel/andalusia/gypsy-caves/IMG_9389.jpg";
import andalusiaGypsyCaves4 from "images/travel/andalusia/gypsy-caves/IMG_9390.jpg";
import andalusiaGypsyCaves5 from "images/travel/andalusia/gypsy-caves/IMG_9397.jpg";
import andalusiaGypsyCaves6 from "images/travel/andalusia/gypsy-caves/IMG_9398.jpg";
import andalusiaGypsyCaves7 from "images/travel/andalusia/gypsy-caves/IMG_9401.jpg";

import andalusiaFlowerGarden1 from "images/travel/andalusia/flower-garden/IMG_9452.jpg";
import andalusiaFlowerGarden2 from "images/travel/andalusia/flower-garden/IMG_9456.jpg";
import andalusiaFlowerGarden3 from "images/travel/andalusia/flower-garden/IMG_9466.jpg";
import andalusiaFlowerGarden4 from "images/travel/andalusia/flower-garden/IMG_9467.jpg";
import andalusiaFlowerGarden5 from "images/travel/andalusia/flower-garden/IMG_9471.jpg";
import andalusiaFlowerGarden6 from "images/travel/andalusia/flower-garden/IMG_9475.jpg";
import andalusiaFlowerGarden7 from "images/travel/andalusia/flower-garden/IMG_9481.jpg";
import andalusiaFlowerGarden8 from "images/travel/andalusia/flower-garden/IMG_9550.jpg";

import bestPhotoshoot1 from "images/travel/andalusia/best-photoshoot/IMG_9505.jpg";
import bestPhotoshoot2 from "images/travel/andalusia/best-photoshoot/IMG_9508.jpg";
import bestPhotoshoot3 from "images/travel/andalusia/best-photoshoot/IMG_9509.jpg";
import bestPhotoshoot4 from "images/travel/andalusia/best-photoshoot/IMG_9510.jpg";
import bestPhotoshoot5 from "images/travel/andalusia/best-photoshoot/IMG_9512.jpg";
import bestPhotoshoot6 from "images/travel/andalusia/best-photoshoot/IMG_9516.jpg";
import bestPhotoshoot7 from "images/travel/andalusia/best-photoshoot/IMG_9517.jpg";
import bestPhotoshoot8 from "images/travel/andalusia/best-photoshoot/IMG_9522.jpg";

import andalusiaPalacePregame1 from "images/travel/andalusia/palace-pregame/IMG_9620c.jpg";
import andalusiaPalacePregame2 from "images/travel/andalusia/palace-pregame/IMG_9621c.jpg";
import andalusiaPalacePregame3 from "images/travel/andalusia/palace-pregame/IMG_9625c.jpg";
import andalusiaPalacePregame4 from "images/travel/andalusia/palace-pregame/IMG_9628c.jpg";
import andalusiaPalacePregame5 from "images/travel/andalusia/palace-pregame/IMG_9629c.jpg";
import andalusiaPalacePregame6 from "images/travel/andalusia/palace-pregame/IMG_9635c.jpg";
import andalusiaPalacePregame7 from "images/travel/andalusia/palace-pregame/IMG_9637c.jpg";

import andalusiaLounging1 from "images/travel/andalusia/lounging/IMG_9718.jpg";
import andalusiaLounging2 from "images/travel/andalusia/lounging/IMG_9720.jpg";
import andalusiaLounging3 from "images/travel/andalusia/lounging/IMG_9721.jpg";
import andalusiaLounging4 from "images/travel/andalusia/lounging/IMG_9763.jpg";
import andalusiaLounging5 from "images/travel/andalusia/lounging/IMG_9764.jpg";
import andalusiaLounging6 from "images/travel/andalusia/lounging/IMG_9765.jpg";
import andalusiaLounging7 from "images/travel/andalusia/lounging/IMG_9768.jpg";

import onCall1 from "images/travel/andalusia/on-call/IMG_9735.jpg";
import onCall2 from "images/travel/andalusia/on-call/IMG_9736.jpg";
import onCall3 from "images/travel/andalusia/on-call/IMG_9737.jpg";
import onCall4 from "images/travel/andalusia/on-call/IMG_9738.jpg";
import onCall5 from "images/travel/andalusia/on-call/IMG_9771.jpg";
import onCall6 from "images/travel/andalusia/on-call/IMG_9836.jpg";
import onCall7 from "images/travel/andalusia/on-call/IMG_9838.jpg";

import nasdridFavorites1 from "images/travel/andalusia/nasdrid-favorites/IMG_9683.jpg";
import nasdridFavorites2 from "images/travel/andalusia/nasdrid-favorites/IMG_9688.jpg";
import nasdridFavorites3 from "images/travel/andalusia/nasdrid-favorites/IMG_9691.jpg";
import nasdridFavorites4 from "images/travel/andalusia/nasdrid-favorites/IMG_9695.jpg";
import nasdridFavorites5 from "images/travel/andalusia/nasdrid-favorites/IMG_9707.jpg";
import nasdridFavorites6 from "images/travel/andalusia/nasdrid-favorites/IMG_9709.jpg";
import nasdridFavorites7 from "images/travel/andalusia/nasdrid-favorites/IMG_9722.jpg";
import nasdridFavorites8 from "images/travel/andalusia/nasdrid-favorites/IMG_9727.jpg";
import nasdridFavorites9 from "images/travel/andalusia/nasdrid-favorites/IMG_9828.jpg";
import nasdridFavorites10 from "images/travel/andalusia/nasdrid-favorites/IMG_9832.jpg";

const ImageReelMap = {
  externalGranada: {
    title: "External Granada",
    imageMap: [
      {
        image: externalGranada1,
        description:
          "Much of Granada is victim to vandalism, giving it an attractively edgy look."
      },
      {
        image: externalGranada2,
        description:
          "Mirador San Nicolás. A jewelry seller (left) snaps a photo of the massive Alhambra."
      },
      {
        image: externalGranada3,
        description:
          "Hiking upward to a better viewer, the Alhambra recedes in the distance."
      },
      {
        image: externalGranada4,
        description: "Catholic influence is inescapable."
      },
      {
        image: externalGranada5,
        description:
          "Mariano Fernandez Santiago, known as 'Chorrojumo': An extraordinary gypsy of the 19th century."
      },
      {
        image: externalGranada6,
        description: "Lost but not worried."
      }
    ]
  },
  gypsyCaves: {
    title: "Gypsy Caves",
    imageMap: [
      {
        image: andalusiaGypsyCaves1,
        description: "Museo Cuevas del Sacromonte"
      },
      { image: andalusiaGypsyCaves2, description: "Peep the window" },
      {
        image: andalusiaGypsyCaves3,
        description: "Weaving station"
      },
      {
        image: andalusiaGypsyCaves4,
        description: "Cultured and confused"
      },
      {
        image: andalusiaGypsyCaves5,
        description:
          "Pretending my surroundings are nothing less than ordinary."
      },
      {
        image: andalusiaGypsyCaves6,
        description: "Small table making me look taller than I actually am."
      },
      {
        image: andalusiaGypsyCaves7,
        description:
          "An old tin of Cola-Cao, a sugary health drink marketed for children."
      }
    ]
  },
  flowerGarden: {
    title: "Flower Garden",
    imageMap: [
      {
        image: andalusiaFlowerGarden1,
        description: "Jardines del Generalife"
      },
      {
        image: andalusiaFlowerGarden2,
        description: "🌸"
      },
      {
        image: andalusiaFlowerGarden3,
        description:
          '"Fresh roses in my garden need the rain. I\'ve been hoping for clouds, but the sun remains" - Juke Ross'
      },
      {
        image: andalusiaFlowerGarden4,
        description: "💮"
      },
      {
        image: andalusiaFlowerGarden5,
        description: '"Never refer to me as an item. I\'m a bird" - Big Bird'
      },
      {
        image: andalusiaFlowerGarden6,
        description: "visually spiky, yet delicate to the touch"
      },
      {
        image: andalusiaFlowerGarden7,
        description: "💐"
      },
      {
        image: andalusiaFlowerGarden8,
        description:
          'To describe this flower as "vanilla" would be both accurate and an injustice.'
      }
    ]
  },
  bestPhotoshoot: {
    title: '"The best photoshoot"',
    imageMap: [
      { image: bestPhotoshoot1, description: "Generalife entrance" },
      { image: bestPhotoshoot2, description: "🤓" },
      { image: bestPhotoshoot3, description: "*swapping glasses*" },
      { image: bestPhotoshoot4, description: "😎" },
      { image: bestPhotoshoot5, description: "*swapping models*" },
      { image: bestPhotoshoot6, description: "🤙🤙" },
      { image: bestPhotoshoot7, description: "*change in lighting*" },
      {
        image: bestPhotoshoot8,
        description: "This was around when I lost my selfie stick"
      }
    ]
  },
  palacePregame: {
    title: "Palace Pregame",
    imageMap: [
      {
        image: andalusiaPalacePregame1,
        description: "Restaurante Jardines Alberto"
      },
      {
        image: andalusiaPalacePregame2,
        description: "Koi pond 🐠"
      },
      { image: andalusiaPalacePregame3, description: "¡Salud! 🍻" },
      {
        image: andalusiaPalacePregame4,
        description: "Cerveza primero de muchos"
      },
      {
        image: andalusiaPalacePregame5,
        description: "hmm 🤨"
      },
      {
        image: andalusiaPalacePregame6,
        description: "🍺🍺🍺"
      },
      {
        image: andalusiaPalacePregame7,
        description: "Our favorite beer was the Alhambra Reserva Roja"
      }
    ]
  },
  lounging: {
    title: "Lounging",
    imageMap: [
      {
        image: andalusiaLounging1,
        description: "Court of the lions 🦁"
      },
      { image: andalusiaLounging2, description: "✌" },
      {
        image: andalusiaLounging3,
        description: "🦁"
      },
      {
        image: andalusiaLounging4,
        description: "☀️🕶️☀️"
      },
      {
        image: andalusiaLounging5,
        description: "Amazed we were allowed to lie on the ground."
      },
      {
        image: andalusiaLounging6,
        description: "No one else followed our example."
      },
      {
        image: andalusiaLounging7,
        description: "I'm at the beach 🏖️"
      }
    ]
  },
  onCall: {
    title: "On-call: A Sebastian featurette",
    imageMap: [
      { image: onCall1, description: "Time to make some calls 📱" },
      {
        image: onCall2,
        description: '"Did you pick up the shipment?"'
      },
      {
        image: onCall3,
        description: '"No, I specifically asked for 20 virgins!" 😤'
      },
      { image: onCall4, description: "Business never sleeps 👔" },
      { image: onCall5, description: "Is this a bad time?" },
      { image: onCall6, description: "His phone was actually broken 📵" },
      { image: onCall7, description: "Oscar-worthy 🎭" }
    ]
  },
  nasdridFavorites: {
    title: "Nasdrid favorites",
    imageMap: [
      { image: nasdridFavorites1, description: "A little princess 👸" },
      {
        image: nasdridFavorites2,
        description: "Probably about to take a call 🙄"
      },
      { image: nasdridFavorites3, description: "Court of the Myrtles" },
      { image: nasdridFavorites4, description: "🏊‍♂️" },
      { image: nasdridFavorites5, description: "🙏" },
      { image: nasdridFavorites6, description: "Perfect 🖼️" },
      { image: nasdridFavorites7, description: "💪💪" },
      { image: nasdridFavorites8, description: "Not what it looks like 💩" },
      {
        image: nasdridFavorites9,
        description: '"Take a picture with him, he\'s famous!"'
      },
      { image: nasdridFavorites10, description: "Just breathe 🧘‍♂️" }
    ]
  }
};

export default ImageReelMap;
