import React from "react";
import SKToolbar from "SKComponents/SKToolbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { initializeTracker } from "tracking/tracker";
import HomePage from "./components/HomePage";
import AboutPage from "./components/AboutPage";
import BookReviewsPage from "./components/BookReviewsPage";
import TravelPage from "./components/TravelPage";
import PageBase from "./components/PageBase";
import Andalusia from "./components/travelPages/Andalusia";
import TabMap from "./TabMap";

function App() {
  initializeTracker();

  return (
    <Router>
      <SKToolbar tabMapping={TabMap} />
      <PageBase>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/travel" component={TravelPage} />
          <Route exact path="/books" component={BookReviewsPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/travel/andalusia" component={Andalusia} />
          <Route path="/" component={HomePage} />
        </Switch>
      </PageBase>
    </Router>
  );
}

export default App;
