import React from "react";
import styled from "styled-components";
import profile from "images/profile-pic.png";
import SKTitle from "SKComponents/SKTitle";
import SKImage from "SKComponents/SKImage";

const StyledComponent = styled.div``;

function SahajComponent({ tint, caption }) {
  return (
    <StyledComponent>
      <SKImage image={profile} tint={tint} caption={caption} />
      <SKTitle title={"This is only the beginning"} />
    </StyledComponent>
  );
}

export default SahajComponent;
