import React, { Fragment } from "react";
import SKCover from "SKComponents/SKCover";
import SKDiv from "SKComponents/SKDiv";

function TravelPage() {
  return (
    <Fragment>
      <SKDiv style={{ marginTop: "15vmin" }} />
      <SKCover
        title={"Love for Andalusia"}
        caption={"feat. a Peruvian Prince"}
        highlights={"images/travel/andalusia"}
        fromTab={"travel"}
        tab={"andalusia"}
      />
    </Fragment>
  );
}

export default TravelPage;
