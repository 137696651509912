import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useOnScreen from "SKComponents/hooks/useOnScreen";
import ImageBannerMap from "constants/ImageBannerMap";
import { FADED_STEEL } from "SKComponents/utils/Colors";
import { FADED_COLOR_MAP } from "SKComponents/utils/ColorMaps";
import SKQuote from "./SKQuote";

const StyledImageWrapper = styled.div`
  position: relative;
  height: 75vh;
  width: 95%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 75vh;
  object-fit: cover;
  object-position: 85% 50%
  visibility: ${props => (props.load ? "visible" : "hidden")};
`;

const StyledQuote = styled(SKQuote)`
  position: absolute;
  top: 5vmin;
  left: 5vmin;
  width: 50vw;
  text-shadow: 0 0 2vmin black;
`;

const Dummy = styled.div``;

// *** end quote with: "not the guy in this photo"
function SKBanner({ name, caption, tint, vert = true, ...props }) {
  const [hover, setHover] = useState(false);
  const [load, setLoad] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useOnScreen(vert);

  const image = ImageBannerMap[name];

  const _onMouseEnter = () => {
    setHover(true);
  };

  const _onMouseLeave = () => {
    setHover(false);
  };

  const _onMouseMove = e => {
    setPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const _onLoad = () => {
    setLoad(true);
  };

  return (
    <StyledImageWrapper
      ref={ref}
      onMouseMove={_onMouseMove}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <StyledImage src={image} load={load} onLoad={_onLoad} />
      <StyledQuote
        quote={
          "Each friend represents a world in us, a world possibly not born until they arrive, and it is only by this meeting that a new world is born."
        }
      />
    </StyledImageWrapper>
  );
}

SKBanner.propTypes = {
  image: PropTypes.string.isRequired,
  caption: PropTypes.string,
  tint: PropTypes.string
};

export default SKBanner;
