import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledFlex = styled.div`
  display: flex;
  ${props => `flex-direction: ${props.vertical ? "column" : "row"}`}
  justify-content: ${props => {
    switch (props.spread) {
      case "evenly":
        return "space-evenly";
      case "between":
        return "space-between";
      case "around":
        return "space-around";
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
  flex-wrap: ${props => props.wrap || "wrap"}
`;

function SKFlex(props) {
  return <StyledFlex {...props}>{props.children}</StyledFlex>;
}

SKFlex.propTypes = {
  vertical: PropTypes.bool,
  spread: PropTypes.string
};

export default SKFlex;
