import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SKDiv from "./SKDiv";

const StyledTitle = styled(SKDiv)`
  padding: 10px;
  font-size: ${props => {
    switch (props.size) {
      case "xs":
        return "calc(5px + 1vmin)";
      case "s":
        return "calc(10px + 2vmin)";
      case "m":
        return "calc(15px + 3vmin)";
      case "l":
        return "calc(20px + 4vmin)";
      case "xl":
        return "calc(30px + 6vmin)";
      case "xxl":
        return "calc(40px + 8vmin)";
      case "xxxl":
        return "calc(50px + 10vmin)";
      default:
        return "calc(10px + 2vmin)";
    }
  }};
`;

function SKTitle({ title, size = "s", ...props }) {
  return (
    <StyledTitle size={size} {...props}>
      {title}
    </StyledTitle>
  );
}

SKTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default SKTitle;
