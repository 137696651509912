import React from "react";
import { useHistory } from "react-router-dom";
import SKImage from "SKComponents/SKImage";
import SKFlex from "SKComponents/SKFlex";
import ImageHighlightsMap from "constants/ImageHighlightsMap";
import styled from "styled-components";

const StyledImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 50vmin;
  width: 90vmin;
`;

const StyledFlex = styled(SKFlex)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const StyledBlur = styled.div`
  position: absolute;
  height: 100%;
  left: -1px;
  width: 10vmin;
  background: linear-gradient(to left, transparent, #282c34);
  pointer-events: none;
`;

const StyledBlur2 = styled.div`
  position: absolute;
  height: 100%;
  left: calc(100% - 10vmin + 1px);
  width: 10vmin;
  background: linear-gradient(to left, #282c34, transparent);
  pointer-events: none;
`;

const StyledTitle = styled.div`
  position: absolute;
  top: 6.5vmin;
  left: 5vmin;
  font-size: 5vmin;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.75);
  pointer-events: none;
`;

const StyledTitle2 = styled.div`
  position: absolute;
  top: 14vmin;
  left: 5vmin;
  font-size: 2.5vmin;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.75);
  pointer-events: none;
`;

function SKCover({ title, caption, fromTab, tab }) {
  let history = useHistory();
  let highlightImages = ImageHighlightsMap[tab];

  const onClick = () => {
    history.push(`${fromTab}/${tab}`);
  };

  return (
    <StyledImageWrapper>
      <StyledFlex onClick={onClick} spread={"left"} wrap={"nowrap"}>
        {highlightImages.map((fileName, index) => (
          <SKImage key={index} image={fileName} vert={false} />
        ))}
      </StyledFlex>
      <StyledBlur />
      <StyledBlur2 />
      <StyledTitle>{title}</StyledTitle>
      <StyledTitle2>{caption}</StyledTitle2>
    </StyledImageWrapper>
  );
}

SKCover.propTypes = {};

export default SKCover;
