import React, { Fragment } from "react";
import styled from "styled-components";
import SKDiv from "SKComponents/SKDiv";
import SKBanner from "SKComponents/SKBanner";
import AndalusiaReelMap from "constants/AndalusiaReelMap";
import SKImageReel from "SKComponents/SKImageReel";

const StyledTitle = styled.div`
  font-size: 12vmin;
  padding-top: 5vmin;
`;

const StyledSubTitle = styled.div`
  font-size: 6vmin;
  padding-bottom: 0vmin;
`;

const StyledTitle2 = styled.div`
  padding-top: 5vmin;
  font-size: 8vmin;
`;

const StyledInfo = styled.div`
  text-align: justify;
  width: 75%;
  padding-top: 5vmin;
  padding-bottom: 5vmin;
`;

function Andalusia() {
  return (
    <Fragment>
      <SKDiv style={{ marginTop: "0vmin" }} />
      <SKBanner name={"andalusia"} />
      <StyledTitle>Spirit of Andalusia</StyledTitle>
      <StyledSubTitle>feat. A Peruvian Prince</StyledSubTitle>
      <StyledInfo>
        Under guidance of the devilshly charming Sebastian Escalanté, what I had
        intended to be an efficient tour of Southern Spain turned into a
        spontaneous adventure. From the savory food to the architectural beauty,
        and the manic parties which for good reason did not make it into this
        gallery, the Spirit of Andalusia has rightfully earned a place in my
        heart, as has the new world borne onto me by my friend/rival [insert
        Instagram].
      </StyledInfo>
      <StyledTitle2>Granada's Arrival</StyledTitle2>
      <StyledInfo>
        Saving the best for first, we arrived before dawn at the home of the
        Alhambra, a superior fortress complex which encloses a palace of
        unmatched Arabic influence. With extra time on our hands, we leisurely
        hiked the city's outskirts, exploring Gyspy artifacts and posing along
        the crumbly aesthetic.
      </StyledInfo>
      <SKImageReel data={AndalusiaReelMap.externalGranada} />
      <SKImageReel data={AndalusiaReelMap.gypsyCaves} />
      <SKImageReel data={AndalusiaReelMap.flowerGarden} />
      <SKImageReel data={AndalusiaReelMap.bestPhotoshoot} />
      <StyledTitle2>Nasdrid Palace</StyledTitle2>
      <StyledInfo>
        Before enjoying the Alhambra's main attraction, the Nasdrid Palace, we
        decided to relax and enjoy varieties of the local brand of beer,
        Cervezas Alhambra. Naturally, we got carried away.
      </StyledInfo>
      <SKImageReel data={AndalusiaReelMap.palacePregame} />
      <SKImageReel data={AndalusiaReelMap.lounging} />
      <SKImageReel data={AndalusiaReelMap.onCall} />
      <SKImageReel data={AndalusiaReelMap.nasdridFavorites} />
    </Fragment>
  );
}

export default Andalusia;
