import andalusia1 from "images/travel/andalusia/IMG_0093.jpg";
import andalusia2 from "images/travel/andalusia/IMG_0112.jpg";
import andalusia3 from "images/travel/andalusia/IMG_9832.jpg";

const ImageHighlightsMap = {
  andalusia: [
    andalusia1,
    andalusia2,
    andalusia3,
    andalusia1,
    andalusia2,
    andalusia3
  ]
};

export default ImageHighlightsMap;
