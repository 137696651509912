import React, { Fragment } from "react";
import SKDiv from "SKComponents/SKDiv";

function BookReviews() {
  return (
    <Fragment>
      <SKDiv style={{ marginTop: "15vmin" }} />
      <SKDiv style={{ width: "75vmin" }}>
        Coming Soon: An analysis of John Green
      </SKDiv>
    </Fragment>
  );
}

export default BookReviews;
