import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SKDiv from "./SKDiv";

const StyledTitle = styled(SKDiv)`
  text-align: left;
  padding-left: ${props => props.padding}vmin;
  font-size: ${props => {
    switch (props.size) {
      case "xs":
        return "calc(5px + 1vmin)";
      case "s":
        return "calc(10px + 1.5vw + 1vh)";
      case "m":
        return "calc(15px + 2vmin)";
      case "l":
        return "calc(20px + 4vmin)";
      case "xl":
        return "calc(30px + 6vmin)";
      case "xxl":
        return "calc(40px + 8vmin)";
      case "xxxl":
        return "calc(50px + 10vmin)";
      default:
        return "calc(10px + 2vmin)";
    }
  }};
`;

const getLongestLineLength = lines => {
  let longestLineLength = 0;
  lines.forEach(line => {
    longestLineLength = Math.max(longestLineLength, line.length);
  });
  return longestLineLength;
};

function SKQuote({ quote, breakPoints, size = "s", ...props }) {
  // split quote by phrases where the commas are (include comma, but remove following white space)
  const theQuote = `"${quote}"`;
  //const quoteLines = theQuote.split(/(?<=,)\s/);
  const quoteLines = theQuote.split(",");
  const longestLineLength = getLongestLineLength(quoteLines);

  return (
    <StyledTitle size={size} {...props}>
      {theQuote}
    </StyledTitle>
  );
}

SKQuote.propTypes = {
  quote: PropTypes.string.isRequired
};

export default SKQuote;
