import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { trackPageView } from "tracking/tracker";
import PropTypes from "prop-types";
import styled from "styled-components";
import SKFlex from "SKComponents/SKFlex";
import SKTitle from "SKComponents/SKTitle";

const StyledToolbar = styled.div`
  background: linear-gradient(to bottom, black, transparent);
  width: 100%;
  font-size: 21px;
  position: fixed;
  color: white;
  z-index: 5;
`;

const TabUnderline = styled.div`
  background-color: white;
  margin: auto;
  width: ${props => (props.tabRoute === props.currentRoute ? 75 : 0)}%;
  height: 3px;
  transition: width 0.15s ease-out;
  :focus {
    outline: 0;
  }
`;

const StyledFlex = styled(SKFlex)`
  width: ${props => (props.windowWidth > 600 ? 50 : 100)}%;
  margin: auto;
`;

const StyledTab = styled.div`
  :hover {
    & > ${TabUnderline} {
      width: ${props => (props.tabRoute === props.currentRoute ? 90 : 60)}%;
    }
  }
  :focus {
    outline: 0;
  }
`;

function SKToolbar({ tabMapping }) {
  let history = useHistory();
  const pathName = history.location.pathname;
  const currentTab = "/" + pathName.split("/")[1];

  const [currentRoute, setCurrentRoute] = useState(currentTab);

  trackPageView(currentRoute);

  useEffect(() => {
    setCurrentRoute(currentTab);
  }, [currentTab]);

  const handleOnClick = tabRoute => {
    setCurrentRoute(tabRoute);
    history.push(tabRoute);
  };

  return (
    <StyledToolbar>
      <StyledFlex>
        {tabMapping.map(({ tabName, tabRoute }, index) => (
          <StyledTab
            key={index}
            tabRoute={tabRoute}
            currentRoute={currentRoute}
            onClick={() => handleOnClick(tabRoute)}
          >
            <SKTitle title={tabName} />
            <TabUnderline
              tabRoute={tabRoute}
              currentRoute={currentRoute}
              className="test"
            />
          </StyledTab>
        ))}
      </StyledFlex>
    </StyledToolbar>
  );
}

SKToolbar.propTypes = {
  tabMapping: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabRoute: PropTypes.string.isRequired
    })
  )
};

export default SKToolbar;
